<template>
    <div class="viewContainer" v-loading="loading">
        <!-- 内容 -->
        <div class="topTitle">
            {{this.$route.name == 'SaleSupport'?'销售服务':'技术支持'}}
        </div>

        <hr  class="hr">

        <div class="content">
            <div class="phone">
                <div class="title">
                    <img src="@/assets/image/services/tel.png" alt="">
                    <div class="text">{{$route.name == 'SaleSupport'?'销售电话':'技术电话'}}</div>
                    <div class="greyLine"></div>
                </div>
                <div class="tips">有任何需求，请联系我们</div>
                <div>
                    <el-row :gutter="0" style="width:100%" v-for="(item,index) in content" :key="index" class="phoneType" :type="isMoPhone?'':'flex'">
                        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8" style="padding:0px">
                            <div class="typeName">{{item.title}}</div>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16" style="padding:0px">
                            <div class="typeContent">
                                <div class="phoneItem" v-for="(t,i) in item.tels" :key="i">{{t}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                
            </div>
            <div class="email">
                <div class="title">
                    <img src="@/assets/image/services/mail.png" alt="">
                    <div class="text">业务邮件</div>
                    <div class="greyLine"></div>
                </div>
                <div class="tips">在填写时，请正确并详细的填写您的信息，如无公司名称，则请填写学校名称或城市名称+您的姓名，以便我们更好的为您提供相应的销售服务及技术支持，谢谢合作。</div>
                <div class="formPart">
                    <el-form ref="form" :model="form" label-width="100px">
                        <div class="blueText">发件人：</div>
                        <el-form-item label="名称：">
                            <el-input v-model="form.name" placeholder="名称"></el-input>
                        </el-form-item>
                        <el-form-item label="Email：">
                            <el-input v-model="form.email" placeholder="Email"></el-input>
                        </el-form-item>
                        <el-form-item label="联系电话：">
                            <el-input v-model="form.phone" placeholder="联系电话"></el-input>
                        </el-form-item>
                        <el-form-item label="传真：">
                            <el-input v-model="form.fax" placeholder="传真"></el-input>
                        </el-form-item>
                        <el-form-item label="公司名称：">
                            <el-input v-model="form.companyName" placeholder="公司名称"></el-input>
                        </el-form-item>
                        <el-form-item label="公司网址：">
                            <el-input v-model="form.companyWebsite" placeholder="公司网址"></el-input>
                        </el-form-item>
                        <div class="blueText">收件人：
                            <img v-if="$route.name == 'SaleSupport'" style="display:inline-block; margin-left: 22px;" src="@/assets/image/services/sale_mail.png" alt="">
                            <img v-else style="display:inline-block; margin-left: 22px;" src="@/assets/image/services/tech_mail.png" alt="">
                        </div>
                        <el-form-item label="邮件主题：">
                            <el-input v-model="form.emailSubject" placeholder="邮件主题"></el-input>
                        </el-form-item>
                        <el-form-item label="邮件内容：">
                            <el-input type="textarea" :autosize="{ minRows: 4, }" v-model="form.emailContent" placeholder="邮件内容"></el-input>
                        </el-form-item>
                        <div class="btnClass">
                           <el-button type="primary" :loading="submitLoading"  @click="handleSubmit" style="margin-right:30px">发送邮件</el-button>
                           <el-button @click="handleReset" style="color: #fff;background-color: #122b40;border-color: #122b40;">重置</el-button>
                        </div>
                        
                    </el-form>
                </div>
            </div>
            <div class="process" v-if="$route.name == 'SaleSupport'">
                <div class="title">
                    <img src="@/assets/image/services/buy_process.png" alt="">
                    <div class="text">购买流程</div>
                    <div class="greyLine"></div>
                </div>
                <div>
                    <a class="buyLink" href="https://www.wch.cn/services/buy_process.html">请点击查看购买流程</a>
                </div>
            </div>
        </div>
        <el-dialog
         title="提示"
         :visible.sync="dialogVisible"
         width="400px"
        >
            <div style="width:100%;font-size:20px; text-align:center;"><i style="color:green;" class="el-icon-circle-check"></i> 邮件已发送！</div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { saleSupport, saleSendEmail, techSupport, techSendEmail,} from '@/services/serviceSupport.js'
export default {
  name: "saleSupport",
  components:{
  },
  data() {
    return {
        windowWidth:window.innerWidth,
        isMoPhone:false,
        loading:false,
        submitLoading:false,
        content:[],
        form: {
        },
        dialogVisible:false,
    };
  },
  computed: {
  },
  created() {
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize)
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      if (newWidth < 768) {
        this.isMoPhone = true
      }else{
        this.isMoPhone = false
      }
    },
  },
  mounted() {
    if (this.windowWidth < 768) {
        this.isMoPhone = true
    }else{
        this.isMoPhone = false
    }
  },
  activated(){
    this.init()
  },
  methods:{
    handleResize() {
      this.windowWidth = window.innerWidth
    },
    init(){
        this.form = {}
        console.log('this.$route---',this.$route)
        if(this.$route.name == 'SaleSupport'){//销售
            this.getSaleSupport()
        }else if(this.$route.name =='TechnicalSupport'){//技术
            this.getTechSupport()
        }
    },

    //销售服务
    getSaleSupport(){
        this.content = []
        this.loading = true;
        saleSupport().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
                console.log('销售---', this.content)
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //技术支持
    getTechSupport(){
        this.content = {}
        this.loading = true;
        techSupport().then(res => {
            if(res.data.code===0 && res.data.data){
                this.content = res.data.data
            }else{
                this.$message.error(res.data.message);
            }
            this.loading = false;
        });
    },
    //发送邮件
    handleSubmit(){
        
        let params = {...this.form}
        this.submitLoading = true;
        if(this.$route.name == 'SaleSupport'){//销售
            saleSendEmail(params).then(res => {
                if(res.data.code===0 ){
                    this.dialogVisible = true ;
                    this.handleReset()
                }else{
                    this.$message.error(res.data.message);
                }
                this.submitLoading = false;
            });
        }else if(this.$route.name =='TechnicalSupport'){//技术
            techSendEmail(params).then(res => {
                if(res.data.code===0 ){
                    this.dialogVisible = true ;
                    this.handleReset()
                }else{
                    this.$message.error(res.data.message);
                }
                this.submitLoading = false;
            });
        }
        

    },
    //重置
    handleReset(){
        this.form = {}

    },
  }
};
</script>
<style scoped lang="less">
 .viewContainer{
    width: 100%;
    text-align: left;
    font-family: Microsoft YaHei, Arial, Helvetica, sans-serif !important;

    .topTitle{
        font-size: 22px;
        color: #002c85;
        font-weight: 600;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .hr{
        border-top: 1px solid #cbd0d4;
        margin: 25px 0px;
    }
    .content{
        .phone{
            margin: 40px 0px;
            .phoneType{
                margin-bottom: 20px;
                font-size: 16px;
                .typeName{
                    background: #1ea9fb;
                    color: #fff;
                    text-align: center;
                    font-size: 20px;
                    line-height: 36px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .typeContent{
                    height: 100%;
                    display: flex;
                    padding: 10px;
                    box-sizing: border-box;
                    flex-wrap: wrap;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, .1);
                    .phoneItem{
                        width: 50%;
                        text-align: center;
                        margin: 10px 0;
                    }
                }
            }
        }
        .email{
            .formPart{
                width: 60%;
                margin: 0 auto;
                font-size: 14px;
                color: #030303;
                /deep/.el-form-item__label{
                    color: #030303;
                    font-weight: 600;
                    letter-spacing: 2px;
                }
                .blueText{
                    font-size: 18px;
                    color: #1ea9fb;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    font-weight: bolder;
                }
                .btnClass{
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .process{
            margin: 40px 0px;
            .buyLink{
                margin-left: 8%;
                font-size: 16px;
                line-height: 24px;
                display: block;
                margin-top: 25px;
                margin-bottom: 70px;
                color: #1ea9fb;
            }
        }
        .title{
            display: flex;
            align-items: center;
            .text{
                font-size: 26px;
                float: left;
                margin-left: 20px;
            }
            .greyLine{
                margin-left: 20px;
                height: 10px;
                float: left;
                border-bottom: 1px solid #cbd0d4;
                flex: 1;
            }
        }
        .tips{
            margin-top: 20px;
            padding-left: 30px;
            font-size: 14px;
            color: #AFAFAF;
            padding-right: 50px;
            margin-bottom: 20px;
        }
    }
 }
@media screen and (max-width: 768px) {
    .viewContainer .content .email .formPart{
        width: 100%;
    }
    .viewContainer .content .phone .phoneType .typeContent .phoneItem{
        width: 100%;
    }
}
</style>